import * as React from "react"
import Layout from "../components/layout";
import Emoji from "../components/emoji";

// markup
const NotFoundPage = () => {
  return (
    <Layout title="Not found">
      <div className="container pt-12 pb-20 max-w-2xl">
        <h1>Not found <Emoji emoji={"😕"} label="sad face"/></h1>
        <p>Sorry, the page you're looking for does not exist...</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
